jQuery( function($) {
  var select_chain = function(event){
    var selectElm = $(this)
    var chainfor = $(this).data('chainFor');
    var selectedVal
    if (selectElm.prop('tagName') === 'SELECT'){
      selectedVal = "\"" + ($(this).val() ? $(this).val() : "_blank") + "\"";
    } else if (selectElm.attr('type') == 'radio') {
      if (!$(this).prop('checked')) {
        return
      }
      selectedVal = $(this).val();
    } else {
      return
    }
    
    var disable_selector = "[class=" + chainfor.substr(1) + "]";
    var disable_target = $(disable_selector + ", " + disable_selector + " input, " + disable_selector + " select, " +  disable_selector + " textarea");
    var data_selector = "[class=" + chainfor.substr(1) + "][data-parent-is=" + selectedVal + "]";
    var target = $(data_selector + ", " + data_selector + " input, " + data_selector + " select, " + data_selector + " textarea");

    if(target.is(':disabled') && event.type != "change"){
      $(chainfor).hide();
      $(data_selector).show();
    }else{
      disable_target.prop('disabled', true);
      $(chainfor).hide();
      $(data_selector).show();
      target.prop('disabled', false);
    }
  };
  $('.exec-select-chain').each(select_chain).change(select_chain);
});
