import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/public/createUploadLink.js';

const csrfToken = document.querySelector('meta[name=csrf-token]').getAttribute('content');
const client = new ApolloClient({
  link: createUploadLink({
    uri: '/graphql',
    credentials: 'same-origin',
    headers: {
      'X-CSRF-Token': csrfToken
    },
  }),
  cache: new InMemoryCache()
});

const withApollo = Component => ({ ...props }) => (
  <ApolloProvider client={client}>
    <Component {...props} />
  </ApolloProvider>
);

export default withApollo;
