/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'core-js/stable'
import 'regenerator-runtime/runtime'

// 以下、jQuery関係は、Sproketsで読み込み、externalsに入れているためWebpackではimportしない
//import 'jquery/src/jquery'
//import 'jquery-ui/ui/widgets/sortable'
//import 'jquery-ui/ui/widgets/datepicker'
//import 'jquery-ui/ui/i18n/datepicker-ja'
//import 'jquery-ui/ui/widgets/resizable'

import 'daterangepicker/daterangepicker'
import 'moment'

//DataTables
// https://www.datatables.net/download/npm
// ※表画面のbootstrapに応じて、-dtを-bs3や-bs4に変更する必要があるかも
// https://datatables.net/forums/discussion/comment/174474/#Comment_174474
import 'datatables.net'
import 'datatables.net-dt'
import 'datatables.net-dt/css/jquery.dataTables.css'
import 'datatables.net-fixedcolumns'
import 'datatables.net-fixedcolumns-dt'
import 'datatables.net-fixedcolumns-dt/css/fixedColumns.dataTables.css'
import 'datatables.net-fixedheader'
import 'datatables.net-fixedheader-dt'
import 'datatables.net-fixedheader-dt/css/fixedHeader.dataTables.css'
import 'datatables.net-select'
import 'datatables.net-select-dt'
import 'datatables.net-select-dt/css/select.dataTables.css'

// fortawesome
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';

import '../../src/application/common/javascripts/daterangepicker'
import '../../src/application/common/javascripts/jquery.ajax_prefilter.js'
import '../../src/application/common/javascripts/jquery.checkboxes.js'
import '../../src/application/common/javascripts/jquery.dynamic_table.js'
import '../../src/application/common/javascripts/jquery.linkpost.js'
import '../../src/application/common/javascripts/jquery.password_toggle.js'
import '../../src/application/common/javascripts/jquery.searchable_select.js'

import '../../src/application/common/stylesheets/jquery-ui.scss'
import '../../src/application/common/stylesheets/daterangepicker.css'
import '../../src/application/common/javascripts/jquery.select_chain.js'

import Rails from 'rails-ujs'
Rails.start()

// react-rails
var componentRequireContext = require.context('react/src/components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
