jQuery(function($){
  $.fn.initSearchableSelect = function(){
    var ajax = $(this).data('ajax');
    var ajax_option;
    if(ajax){
      ajax_option = {
        url: ajax.url,
        dataType: 'json',
        data: function(params){
          return {
            q: params.term,
            page: params.page,
            per: ajax.per
          };
        },
        processResults: function(data, params){
          params.page = params.page || 1;
          return {
            results: data.results,
            pagination: {
              more: (params.page * ajax.per) < data.count_filtered
            }
          }
        }
      }
    }

    $(this).select2({
      theme: 'bootstrap',
      ajax: ajax_option
    });
  }
  $('.exec-searchable-select select, select.exec-searchable-select').initSearchableSelect();
});
