// Import
import React, { useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';

// Custom Import
import { NoticeItem } from './NoticeItem';
import { NoticesQuery } from './__generated__/NoticesQuery'
import { updateAllNotice } from './__generated__/updateAllNotice'
import withApollo from '../../../services/graphql/withApollo';

// Query
const NOTICE_QUERY = gql`
  query NoticesQuery {
    notices {
      ...notice
    }  
  }
  ${NoticeItem.fragments.data}
`;

const UPDATE_ALL_NOTICE = gql`
  mutation updateAllNotice {
    updateAllNotice(input: {}){
      result
    }
  }
`;

// Component
const Notice = () => {
  // Hooks
  const { data } = useQuery<NoticesQuery>(NOTICE_QUERY);

  const [updateAllNotice] = useMutation<updateAllNotice>(UPDATE_ALL_NOTICE, {
    refetchQueries: ['NoticesQuery'],
  });

  // @ts-ignore
  useEffect(() => feather.replace());

  // Const
  const noticesData = data?.notices;
  const unreadNotices = noticesData?.filter((notice) => !notice.readAt)

  // DOM
  return (
    <>
      <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
        <i data-feather="bell"></i>
        <span className="label">通知</span>
        {unreadNotices?.length > 0 && (
          <span className="badge badge-pill badge-primary">{unreadNotices?.length}</span>
        )}
      </a>
      <div className="dropdown-menu dropdown-menu-right border-0 shadow">
        <div className="dropdown-header">
          <p>お知らせ</p>
          <a onClick={() => updateAllNotice()}>すべて既読にする</a>
        </div>
        <div className="notification-area">
          <ul>
            {noticesData?.map((notice)=>(
              <NoticeItem key={notice.id} notice={notice} />
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

// Default Export
export default withApollo(Notice);
