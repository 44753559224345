$.fn.checkboxes = function() {
  this.each ( function(idx, one) {
    var target = $(one).data('target') + ':not(:disabled)';

    $(target).on('change', function(){
      var checked_cnt = $(target + ':checked').length;
      var all_cnt = $(target).length;
      var all_checked = checked_cnt == all_cnt;
      $(one).prop('checked', all_checked);
    });
  });

  // 全選択・全解除をクリックしたとき(IE向けにonchangeで処理)
  this.change(function(){
    var checked = $(this).is(':checked');
    var target = $(this).data('target') + ':not(:disabled)';

    $(target).prop('checked', checked);
  });

  return this;
};
