jQuery(function ($) {
  moment.locale("ja");
  $('input.daterange').daterangepicker({
    ranges: {
      '今日': [moment(), moment()],
      '昨日': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      '過去7日間': [moment().subtract(6, 'days'), moment()],
      '過去30日間': [moment().subtract(29, 'days'), moment()],
      '今月': [moment().startOf('month'), moment().endOf('month')],
      '先月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    autoUpdateInput: false,
    format: "YYYY/MM/DD",
    locale: { customRangeLabel: "カスタム", cancelLabel: "キャンセル", applyLabel: "OK" }
  });
  $('input.daterange').on('apply.daterangepicker', function (ev, picker) {
    $(this).val(picker.startDate.format('YYYY/MM/DD') + ' - ' + picker.endDate.format('YYYY/MM/DD'));
    $(this).change();
  });
});
