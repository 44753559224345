jQuery(function($){
  var init_password_toggle = function(){
    $(this).on('change', function(){
      var target = $(this).data('toggleFor');
      var checked = $(this).is(':checked');
      if(checked){
        $(target + '[type="password"]').attr('type', "text");
      }else{
        $(target + '[type="text"]').attr('type', "password");
      }
    });
  };

  $('.exec-password-toggle').each(init_password_toggle);
});
