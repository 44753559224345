// Import
import React from 'react';
import { gql, useMutation } from '@apollo/client';

// Custom Import
import { updateNotice } from './__generated__/updateNotice';

// Query
const UPDATE_NOTICE = gql`
  mutation updateNotice($id: ID!) {
    updateNotice(input: { id: $id }) {
      notice {
        id
      }
    }
  }
`;

// Component
export const NoticeItem = ({ notice }) => {
  // Hooks
  const [updateNotice] = useMutation<updateNotice>(UPDATE_NOTICE, {
    refetchQueries: ['NoticesQuery'],
    onCompleted: () => {
      if (url) window.location.href = url;
    },
  });

  // Const
  const { id, message, readAt, url, createdAtText, user } = notice;
  const { image: userImage } = user;

  // DOM
  return (
    <li className={readAt ?  '': 'unread'}>
      <a onClick={() => updateNotice({variables: {id}})}>
        <div className="notification-area__item">
          <div className="m-avatar -sm rounded-circle" style={{backgroundImage: `url(/assets/${userImage})` }}>
          </div>
          <div className="notification-area__contents">
            <div 
              dangerouslySetInnerHTML={{
                __html: message
              }}
            />
            <span className="m-date">{createdAtText}</span>
          </div>
        </div>
      </a>
    </li>
  );
};

// Fragments
NoticeItem.fragments = {
  data: gql`
    fragment notice on Notice {
      id
      message
      url
      readAt
      createdAtText
      user {
        image
      }
    }
  `,
};
